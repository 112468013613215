import React, { useState, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import cx from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';

import useSiteMetadata from 'hooks/useSiteMetadata';

import * as styles from './ContactForm.module.css';

const telRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default function ContactForm() {
  const { shop } = useSiteMetadata();

  const intl = useIntl();

  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState(false);
  const recaptchaRef = useRef();

  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      tel: '',
      message: '',
      shop,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object({
      name: Yup.string().required(
        intl.formatMessage({ id: 'contact.form.error_field_required' }),
      ),
      email: Yup.string()
        .email(intl.formatMessage({ id: 'contact.form.error_invalid_email' }))
        .required(
          intl.formatMessage({ id: 'contact.form.error_field_required' }),
        ),
      tel: Yup.string()
        .matches(
          telRegExp,
          intl.formatMessage({ id: 'contact.form.error_invalid_phone' }),
        )
        .required(
          intl.formatMessage({ id: 'contact.form.error_field_required' }),
        ),
      message: Yup.string().required(
        intl.formatMessage({ id: 'contact.form.error_field_required' }),
      ),
    }),
    onSubmit: async (data) => {
      try {
        const token = await recaptchaRef.current.executeAsync();
        const res = await axios({
          url: 'https://us-east1-resolute-spirit-303614.cloudfunctions.net/serverless-contact-form',
          method: 'post',
          headers: {
            'content-type': 'application/json',
          },
          data: { ...data, 'g-recaptcha-response': token },
        });

        const { success, message } = res.data;
        if (success) {
          setError(false);
          setSubmited(true);
        } else {
          setError(message);
        }
      } catch (err) {
        setError(intl.formatMessage({ id: 'contact.form.error_generic' }));
        throw new Error(err);
      }
    },
  });

  return (
    <form
      className={styles.container}
      onSubmit={handleSubmit}
      name="contact"
      method="post"
    >
      <input type="hidden" name="form-name" value="contact" />
      <div
        className={cx(styles.field, styles.s50, {
          [styles.error]: errors.name && touched.name,
        })}
      >
        <input
          id="name"
          name="name"
          type="text"
          placeholder={intl.formatMessage({ id: 'contact.form.name' })}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          disabled={submited}
        />
        <div className={styles.errorHint}>{errors.name}</div>
      </div>

      <div
        className={cx(styles.field, styles.s50, {
          [styles.error]: errors.email && touched.email,
        })}
      >
        <input
          id="email"
          name="email"
          type="email"
          placeholder={intl.formatMessage({ id: 'contact.form.email' })}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          disabled={submited}
        />
        <div className={styles.errorHint}>{errors.email}</div>
      </div>

      <div
        className={cx(styles.field, {
          [styles.error]: errors.tel && touched.tel,
        })}
      >
        <input
          id="tel"
          name="tel"
          type="tel"
          placeholder={intl.formatMessage({ id: 'contact.form.phone' })}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.subject}
          disabled={submited}
        />
        <div className={styles.errorHint}>{errors.tel}</div>
      </div>

      <div
        className={cx(styles.field, {
          [styles.error]: errors.message && touched.message,
        })}
      >
        <textarea
          id="message"
          name="message"
          placeholder={intl.formatMessage({ id: 'contact.form.message' })}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.message}
          disabled={submited}
        />
        <div className={styles.errorHint}>{errors.message}</div>
      </div>

      {error ? (
        <div className={styles.errorMessage}>
          <p>{error}</p>
        </div>
      ) : null}

      {submited ? (
        <div className={styles.successMessage}>
          <p>{intl.formatMessage({ id: 'contact.form.post_success' })}</p>
        </div>
      ) : (
        <button
          className={styles.submitButton}
          type="submit"
          disabled={!isValid || isSubmitting ? 'disabled' : ''}
        >
          {intl.formatMessage({ id: 'contact.form.submit' })}
        </button>
      )}

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LfHd3MaAAAAAFW7cxp98f3XukTmiclC59xoV-UV"
      />
    </form>
  );
}
