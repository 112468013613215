import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import { GatsbySeo, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import cx from 'classnames';

import Layout from 'components/Layout';
import { ContactForm } from 'components/Contact';
import useSiteMetadata from 'hooks/useSiteMetadata';

import * as styles from './page.module.css';

export const query = graphql`
  query PageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      slug
      title
      content {
        raw
      }
    }
  }
`;

export default function Page({ data, location }) {
  const { siteUrl } = useSiteMetadata();
  const page = get(data, 'contentfulPage');

  if (!page) return null;

  const canonical = `${siteUrl}${location.pathname}`;
  const isContact = page.slug === 'contact';

  return (
    <Layout location={location}>
      <GatsbySeo
        title={page.title}
        // description={page.bodySummary}
        canonical={canonical}
        openGraph={{
          title: page.title,
          // description: page.bodySummary,
          url: canonical,
        }}
      />

      <BreadcrumbJsonLd
        name="Page"
        itemListElements={[
          {
            position: 1,
            name: page.title,
            item: canonical,
          },
        ]}
      />

      <div className={styles.container}>
        <h1 className={styles.title}>{page.title}</h1>
        <div className={cx(styles.text, { [styles.contact]: isContact })}>
          {renderRichText(page.content)}
        </div>
        {isContact ? <ContactForm /> : null}
      </div>
    </Layout>
  );
}
